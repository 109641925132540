
import SalesService from "@/services/SalesService"
import { computed, reactive, ref, defineComponent, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import SalesProductComponent from "@/components/sales/salesProductComponent.vue"

export default defineComponent({
  components: { SalesProductComponent },
  props: {},
  setup(props) {
    const salesService = new SalesService()
    const products = reactive([])
    const scrollable = ref(true)
    const productDialogVisible = ref(false)
    const language = ref("EN")
    const activeProduct = ref(null)
    const salesState = reactive({
      category: null,
      categories: [],
      languages: [],
      addProductDialogVisible: false,
    })

    salesService.getCategories().then((categories: any) => {
      ;[].push.apply(salesState.categories, categories)
    })

    salesService.getLanguages().then((res: any) => {
      if (salesState.languages?.length) {
        salesState.languages.length = 0
      }
      ;[].push.apply(salesState.languages, res)
    })

    function loadProducts() {
      productDialogVisible.value = false
      salesService.getProducts().then((res: any) => {
        products.length = 0
        ;[].push.apply(
          products,
          res.map((row: any) => {
            //reformat categories to easily render them
            return Object.assign(row, {
              categoriesList: row.categories.map((cat: any) => cat.alias),
            })
          })
        )
        loadImages(products)
      })
    }

    function loadImages(products: any) {
      products.forEach((product: any) => {
        product.images.forEach((imageData: any) => {
          salesService.getProductImage(imageData.id).then((imgSrc: any) => {
            imageData.src = imgSrc
          })
        })
      })
    }

    function initializeProductDetails(prod: any) {
      salesState.languages.forEach((lang: any) => {
        const foundDetails = prod.details.find((det: any) => {
          return det.language === lang
        })
        if (!foundDetails) {
          prod.details.push({
            title: "",
            description: "",
            language: lang,
          })
        }
      })
    }

    function showProductDialog(data: any) {
      activeProduct.value = data
      salesState.category = activeProduct.value.categories[0]
      initializeProductDetails(data)
      productDialogVisible.value = true
    }

    loadProducts()

    return {
      products,
      scrollable,
      showProductDialog,
      productDialogVisible,
      salesState,
      language,
      activeProduct,
      loadProducts,
    }
  },
})
